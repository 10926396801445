import React from "react"

/**
 * Content Component
 * This section is used to generate a content block with tagline, title and content text
 * content should be sent as the children html of this component like
 * <Content tagline="text" title="text"><p>Content of the component</p><Content>
 *
 * @param {Object} props
 */
const Content = ({ tagline, title, children, className }) => {
  return (
    <section className={`container content ${className}`}>
      {tagline && (
        <div className="row">
          <div className="col text-center">
            <small className="content__tagline">{tagline}</small>
          </div>
        </div>
      )}
      {title && (
        <div className="row">
          <div className="col text-center">
            <h1 className="content__title">{title}</h1>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col">{children}</div>
      </div>
    </section>
  )
}

export default Content
