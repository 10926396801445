import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ContactForm from "../../components/contactForm"
import Hero from "../../components/hero"
import Services from "../../components/services2"
import Content from "../../components/content"

/**
 * GraphQL Query to retrieve page data
 *
 * @return {Object}
 */
export const ServicesPageQuery = graphql`
  {
    site {
      siteMetadata {
        email
        phone
      }
    }
    hero: file(relativePath: { eq: "pages/about/header-home-erick.jpg" }) {
      sharp: childImageSharp {
        fluid(
          quality: 100
          maxWidth: 1920
          duotone: { highlight: "#15181f", shadow: "#15181f", opacity: 55 }
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

/**
 * Services Page Component
 * This component is used to generate the Services page
 *
 * @param {Object} props
 */
const IdentificacionPersonasPage = ({ data }) => {
  return (
    <Layout isSecondary={true}>
      <SEO title="Identificación de persona" description="" />

      <Hero
        type="simple"
        tagline="Estos son los principales servicios jurídicos que le ofrecemos"
        title="Identificación de Persona"
        background={data.hero.sharp.fluid}
      />

      <Content
        tagline=""
        title="Identificación de Persona"
      >
        <p>
        El fundamento legal lo encontramos en el artículo 5 del Código Civil y en el artículo 440
        del Código Procesal Civil y Mercantil de Guatemala.
        </p>        
        <p>
        Cualquier persona que, constante y públicamente, hubiere usado y fuere conocida con nombre propio
        distinto del que aparece en su partida de nacimiento, o usare nombre incompleto, u omitiere alguno
        de los apellidos que le corresponden, podrá pedir ante un notario, conforme a lo establecido en el
        Código Civil, su identificación, la que se hará constar en escritura pública; el testimonio y una
        copia se presentarán al Registro Civil correspondiente para la anotación de la partida.
          </p>
          <p>
          <strong>¡¡¡Llámenos y haga su cita hoy!!!</strong>
            </p>     
      </Content>

      <Services />

      <ContactForm
        email={data.site.siteMetadata.email}
        phone={data.site.siteMetadata.phone}
      />
    </Layout>
  )
}

export default IdentificacionPersonasPage

